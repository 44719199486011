/*
 * @Description: 随机数生成器
 * @version: 
 * @Author: PSG
 * @Date: 2021-03-24 11:34:29
 * @LastEditors: PSG
 * @LastEditTime: 2021-03-24 11:57:48
 */

// 引入时间戳 随机数前置 36进制 加入随机数长度控制
export function createUniqueId(length) {
  return Number(Math.random().toString().substr(3,length) + Date.now()).toString(36)
}