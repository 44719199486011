/*
 * @Description: 问卷通用方法
 * @version: 
 * @Author: PSG
 * @Date: 2021-03-30 19:42:26
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-27 10:44:47
 */
import createQuestionPrototypeEffect from '@/utils/question.js'
import useCommonConstructEffect from '@/effects/constructEffect'
import globalQuestionnaire from '@/class/useQuestionnaire'
import debounce from '@/utils/debounce'

import { useStore } from 'vuex'
import { ref, watch, watchEffect, computed } from 'vue'
import { questionType2Component } from '@/utils/enum.js'
import { createUniqueId } from '@/utils/random'
import { deepCopy } from '@/utils/deepCopy'



const useCommonQuestionnaireEffect = () => {

  const store = useStore()
  const questionnaire = globalQuestionnaire.getQuestionnaire()

  /**
   * 题目删除、复制操、替换操作作
   */
  const handleEffect = (props) => {
    const createQuestionPrototype = createQuestionPrototypeEffect()
    const { insertLogicTips } = useCommonConstructEffect()
    // 清空与本题关联的逻辑
    // 针对删除、替换操作
    const cutOffRef = (targetQuestion) => {
      const targetSort = targetQuestion.sort
      const targetQuestionId = targetQuestion.questionId
      const targetBeforeQuestions = questionnaire.questions.filter(question => !/^(Part|Page)$/.test(question.questionType) && question.sort < targetSort)
      const targetAfterQuestions = questionnaire.questions.filter(question => !/^(Part|Page)$/.test(question.questionType) && question.sort > targetSort)    
      if (targetBeforeQuestions.length > 0) {
        // 清空与之关联的跳题逻辑
        targetBeforeQuestions.filter(question => !!question.jumpLogic).forEach(question => {
          let jumpLogic = JSON.parse(question.jumpLogic)
          // 条件跳题
          if (Object.prototype.toString.call(jumpLogic) === '[object Object]') {
            const key = findKey(jumpLogic, targetQuestionId)
            question.choices.forEach(choice => { // 清除选项逻辑提示
              if (choice.choiceId === key && choice.jumpLogicTips) choice.jumpLogicTips = null
            })
            delete jumpLogic[key]
            if (Object.keys(jumpLogic).length === 0) {
              jumpLogic = null
              question.isCondJump = false
            }
          } else {
          // 无条件跳题
            if (jumpLogic === targetQuestionId) {
              jumpLogic = null
              question.isNoCondJump = false
            }
          }
          question.jumpLogic = jumpLogic ? JSON.stringify(jumpLogic) : null
        })
      }
      if (targetAfterQuestions.length > 0) {
        // 清空与之相关的题目显示关联
        targetAfterQuestions.filter(question => !!question.showLogic).forEach(question => {
          const showLogic = question.showLogic
          const outCondition = showLogic.includes('&') ? '&' : '|'
          const expMap = exp2Map(showLogic)
          delete expMap[targetQuestionId]
          question.showLogic = Object.values(expMap).join(outCondition) || null
        })
        // 清空与之相关的选项显示关联
        // (单选、多选的选项数据中才存在showLogic)
        targetAfterQuestions
          .filter(question => /^(SINGLE_CHOICE|MULTIPLE_CHOICE)/.test(question.questionType))
          .forEach(question => {
            question.choices.forEach(choice => {
              const showLogic = choice.showLogic
              if (showLogic) {
                const outCondition = showLogic.includes('&') ? '&' : '|'
                const expMap = exp2Map(showLogic)
                delete expMap[targetQuestionId]
                choice.showLogic = Object.values(expMap).join(outCondition) || null
              }
            })
        })
        // 清空与之相关的选项引用、行标题引用关联
        // (单选、多选、矩阵单选、矩阵多选、矩阵量表才存在ref字段)
        targetAfterQuestions
          .filter(question => /^(SINGLE_CHOICE|MULTIPLE_CHOICE|MATRIX_SINGLE_CHOICE|MATRIX_MULTIPLE_CHOICE|MATRIX_SCALE)$/.test(question.questionType))
          .forEach(question => {
            if (question.ref === targetQuestionId) question.ref = null
          })
      }
      // 把条件表达式转为键值对{ 关联的题目ID: 条件表达式 }
      function exp2Map (expression) {
        // 1、分组
        const expressionArr = expression.split(/&|\|/)
        // 2、构建map
        const map = {}
        expressionArr.forEach(express => {
          const reQeustionId = express.replace(/[^\w]/g, ',').split(',')[0]
          map[reQeustionId] = express
        })
        return map
      }
      // 根据value找key
      function findKey (obj,value, compare = (a, b) => a === b) {  
        return Object.keys(obj).find(k => compare(obj[k], value))
      }
    }
    // 清空本题所有逻辑
    // 针对复制操作
    const clearRef = (targetQuestion) => {
      // 清空跳题逻辑
      targetQuestion.jumpLogic = null
      // 清空题目显示逻辑
      targetQuestion.showLogic = null
      // 清空选项显示逻辑
      if (/^(SINGLE_CHOICE|MULTIPLE_CHOICE)$/.test(targetQuestion.questionType)) {
        targetQuestion.choices.forEach(choice => {
          choice.showLogic = null
        })
      }
      // 清空选项引用、行标题引用逻辑
      if (/^(SINGLE_CHOICE|MULTIPLE_CHOICE|MATRIX_SINGLE_CHOICE|MATRIX_MULTIPLE_CHOICE|MATRIX_SCALE)$/.test(targetQuestion.questionType)) {
        targetQuestion.ref = null
      }
    }
    // 题目删除
    const handleDelete = () => {
      const index = props.index
      const questionCopy = deepCopy(questionnaire.questions[index])
      cutOffRef(questionCopy)
      questionnaire.questions.splice(index, 1)
      for (let i = index; i < questionnaire.questions.length; i++) {
        let question = questionnaire.questions[i]
        if (!/(part|page)/.test(question.questionType.toLowerCase())) {
          --question.sort
        }
      }
      // 重新更新逻辑设置的提示信息
      insertLogicTips(questionnaire)
    }
    // 题目复制
    const handleCopy = () => {
      const index = props.index
      const questionCopy = deepCopy(questionnaire.questions[index])
      clearRef(questionCopy)
      questionCopy.questionId = createUniqueId(10)
      questionCopy.sort = questionnaire.questions[index].sort + 1 // 获取原题序号 + 1
      questionnaire.questions.splice(index+1, 0, questionCopy) // 在原题后插入
      for (let i = index + 2; i < questionnaire.questions.length; i++) {
        let question = questionnaire.questions[i]
        if (!/(part|page)/.test(question.questionType.toLowerCase())) {
          ++question.sort
        }
      }
      // 重新更新逻辑设置的提示信息
      insertLogicTips(questionnaire)
    }
    // 题目替换
    const handleChange = (value) => {
      const index = props.index
      const questionnaire = globalQuestionnaire.getQuestionnaire()
      const questionCopy = deepCopy(questionnaire.questions[index])
      cutOffRef(questionCopy)
      const componentName = questionType2Component[value.key]
      const page = questionCopy.page
      const part = questionCopy.part
      const componentData = createQuestionPrototype(componentName, page, part)
      componentData.questionId = questionCopy.questionId
      componentData.sort = questionCopy.sort // 题目序号赋值
      questionnaire.questions.splice(index, 1, componentData)
      // 重新更新逻辑设置的提示信息
      insertLogicTips(questionnaire)
    }
    return { 
      handleDelete, 
      handleCopy,
      handleChange
    }
  }

  /**
   * 题目编辑状态切换
   */
  const questionEditStatusChangeEffect = (props) => {
    const isSettingsActive = ref(false)
    const finished = () => {
      isSettingsActive.value = false
      store.commit('changeCurEditQuestionId', '') // TODO: 原为 changeEditQuestionId
    }
    const edit = (questionId) => {
      debounce(() => {
        store.commit('changeCurEditQuestionId', questionId)
        if (props.isOnlyRead) return
        isSettingsActive.value = true
      }, 'edit', 200)()
    }

    return {
      isSettingsActive,
      edit,
      finished
    }
  }

  /**
   * 将富文本JSON转换为STRING
   * @param {object} questionnaire 
   */
  const stringifyRichTextEffect = (questionnaire) => {
    if (questionnaire.descriptionCSS) {
      questionnaire.description = JSON.stringify(questionnaire.description)
    }
    // 将富文本JSON转换为STRING
    questionnaire.questions.forEach(item => {
      if (item.titleCSS === 'true') {
        item.title = JSON.stringify(item.title)
      }
    })

    for (const key in questionnaire.partMap) {
      const item = questionnaire.partMap[key]
      if (item.descriptionCSS) {
        item.description = JSON.stringify(item.description)
      }
    }
  }

  /**
   * 将已设置行标题引用的矩阵题，填充引用的rowItems字段
   * @param {object} questionnaire 
   */
  const fillRowItemsByRef = (questionnaire) => {
    const refQuestions = questionnaire.questions.filter(question => /^MATRIX_/g.test(question.questionType) && question.ref && question.ref.length > 0)
    refQuestions.forEach(question => {
      const targetQuestion = questionnaire.questions.find(item => item.questionId === question.ref)
      question.rowItems = targetQuestion.choices.map(choice => ({
        'description': choice.description,
        'text': choice.text
      }))
    })
  }

  /**
   * 过滤重构题目产生的章节、页码对象
   */
  const filterQuestionaireEffect = (questionnaire) => {
    const newQuestionnaire = JSON.parse(JSON.stringify(questionnaire))
    newQuestionnaire.questions = newQuestionnaire.questions.filter(item => !/PART|PAGE/.test(item.questionType))
    stringifyRichTextEffect(newQuestionnaire)
    // fillRowItemsByRef(newQuestionnaire)
    return newQuestionnaire
  }

  /**
   * 保存时过滤无效页码
   */
  const filterInvalidPageMapEffect = (questionnaire) => {
    const newQuestionnaire = deepCopy(questionnaire)
    const questions = newQuestionnaire.questions
    if (questions.length > 0) {
      const endQuestion = questions[questions.length - 1]
      const endQuestionPageIndex = endQuestion.page + ''
      const pageArr = Object.keys(newQuestionnaire.pageMap)
      const index = pageArr.indexOf(endQuestionPageIndex)
      for (let i = index + 1; i < pageArr.length; i++) {
        delete newQuestionnaire.pageMap[i]
      }
    }
    return newQuestionnaire
  }

  /**
   * 是否显示行引用显示区域、列标题（针对矩阵单选、矩阵多选、矩阵量表）
   */
  const visibileRowAndColEffect = (isOnlyRead, question) => {
    const visibileRowRefArea = ref(true)
    const visibileColTitle = ref(true)
    watchEffect(() => {
      // 什么时候需要显示preview-scorename，即列标题
      // 1、编辑状态的时候
      // 2、非编辑状态，没有设置行标题引用的时候
      // 3、非编辑状态，设置了行标题引用，且question.refRowItems字段有值的时候
      visibileColTitle.value = (isOnlyRead === undefined || !question.ref || (question.refRowItems && question.refRowItems.length > 0)) ? true : false
      // 什么时候需要显示行引用显示区域
      // 1、非编辑状态，设置了行引用
      visibileRowRefArea.value = (isOnlyRead && question.ref) ? true : false
    })
    return {
      visibileRowRefArea, // 是否显示行引用区域
      visibileColTitle, // 是否显示行标题
    }
  }

  /**
   * 是否显示选项引用区域 (针对单选、多选)
   */
  const visibileRefChoiceEffect = (isOnlyRead, question) => {
    const visibileRefChoiceArea = ref(false)
    // 什么时候需要显示选项引用区域
    // 1、非编辑状态，且设置了选项引用
    watchEffect(() => {
      visibileRefChoiceArea.value = (isOnlyRead && question.ref) ? true : false
    })
    return {
      visibileRefChoiceArea
    }
  }

  /**
   * 答题时是否显示引用逻辑提示信息
   */
  const visibileRefTipsEffect = (question) => {
    // 规则
    //   1、题目存在ref字段且不为空
    //   2、如果引用的选项在页面显示出来了，则把refTIps字段隐藏，否则显示
    let visibleRefTips = false
    const questionType = question.value.questionType
    // 单选题、多选题如果refChoices字段有值，证明页面上面已经显示出来了
    if (/^(SINGLE_CHOICE|MULTIPLE_CHOICE)$/g.test(questionType)) {
      visibleRefTips = computed({
        get: () => {
          if (question.value.refChoices && question.value.refChoices.length > 0) {
            return false
          }
          return true
        }
      })
    } 
    // 矩阵题如果rowItems里面的所有选项中，存在一个visible=true，证明行标题已经显示出来了
    else if (/^MATRIX_/g.test(questionType)) {
      visibleRefTips = computed({
        get: () => {
          if (question.value.rowItems.some(rowItem => rowItem.visible)) {
            return false
          }
          return true
        }
      })
    }
    return {
      visibleRefTips,
    }
  }

  /**
   * 跳题设置
   */
  const jumpLogicEffect = (question) => {
    const isAlreadySetJumpLogic = ref(false)
    watchEffect(() => {
      isAlreadySetJumpLogic.value = question.jumpLogic !== null
    })
    const isOpenJumpLogicModal = ref(false)
    const handleOpenJumpLogicModal = () => {
      isOpenJumpLogicModal.value = true
    }
    const fininshJumpLogicEdit = () => {
      isOpenJumpLogicModal.value = false
    }
    return {
      isAlreadySetJumpLogic,
      isOpenJumpLogicModal,
      handleOpenJumpLogicModal,
      fininshJumpLogicEdit,
    }
  }

  /**
   * 显示设置
   */
  const showLogicEffect = (question) => {
    const isAlreadySetShowLogic = ref(false)
    watchEffect(() => {
      // 1、如果showLogic ！== null，说明已设置显示设置
      // 2、如果是单选题、多选题，符合下面任一两个条件，说明已设置显示设置
      //    2.1、ref !== null
      //    2.2、选项中存在showLogic不为空
      // 3、如果是矩阵单选、矩阵多选题、矩阵量表，ref有值，说明已设置显示设置
      let flag = false
      if (question.showLogic !== null) 
        flag = true
      else if (/^(SINGLE_CHOICE|MULTIPLE_CHOICE)$/.test(question.questionType))
        flag = question.ref ? true : question.choices.some(choice => !!choice.showLogic === true) // 意思是showLogic不为null、undefined
      else if (/^(MATRIX_SINGLE_CHOICE|MATRIX_MULTIPLE_CHOICE|MATRIX_SCALE)$/.test(question.questionType) && question.ref)
        flag = true
      isAlreadySetShowLogic.value = flag
    })
    const isOpenShowLogicModal = ref(false)
    const handleOpenShowLogicModal = () => {
      isOpenShowLogicModal.value = true
    }
    const fininshShowLogicEdit = () => {
      isOpenShowLogicModal.value = false
    }
    const closeShowLogicModal = () => {
      isOpenShowLogicModal.value = false
    }
    return {
      isAlreadySetShowLogic,
      isOpenShowLogicModal,
      handleOpenShowLogicModal,
      fininshShowLogicEdit,
      closeShowLogicModal,
    }
  }

  return { 
    handleEffect,
    questionEditStatusChangeEffect,
    filterQuestionaireEffect,
    filterInvalidPageMapEffect,
    visibileRowAndColEffect,
    visibileRefChoiceEffect,
    visibileRefTipsEffect,
    jumpLogicEffect,
    showLogicEffect,
  }
}

export default useCommonQuestionnaireEffect