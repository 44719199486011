/*
 * @Description: 枚举
 * @version: 
 * @Author: PSG
 * @Date: 2021-03-25 18:18:04
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-29 11:54:35
 */

// 题型组件对应
export const questionType2Component = {
  'SINGLE_CHOICE': 'SingleChoice', // 单选题
  'MULTIPLE_CHOICE': 'MultipleChoice',  // 多选题
  'DROP_DOWN': 'DropDown',  //下拉题
  'SCALE': 'Scale', // 量表
  'MATRIX_SCALE': 'MatrixScale', // 矩阵量表
  'MATRIX_SINGLE_CHOICE': 'MatrixSingleChoice', // 矩阵单选
  'MATRIX_MULTIPLE_CHOICE': 'MatrixMultipleChoice', // 矩阵多选
  'SHORT': 'Short', // 简答题
  'DATE': 'Date',  //日期题
  'PAGE': 'Page', // 分页组件
  'PART': 'Part' // 章节组件
}

// 题型列表
export const questionTypeList = [{
  questionType: 'SINGLE_CHOICE',
  questionName: '单选题',
  component: 'SingleChoice'
}, {
  questionType: 'MULTIPLE_CHOICE',
  questionName: '多选题',
  component: 'MultipleChoice'
}, {
  questionType: 'DROP_DOWN',
  questionName: '下拉题',
  component: 'DropDown'
}, {
  questionType: 'SCALE',
  questionName: '量表',
  component: 'Scale'
}, {
  questionType: 'MATRIX_SCALE',
  questionName: '矩阵量表',
  component: 'MatrixScale'
}, {
  questionType: 'MATRIX_SINGLE_CHOICE',
  questionName: '矩阵单选',
  component: 'MatrixSingleChoice'
}, {
  questionType: 'MATRIX_MULTIPLE_CHOICE',
  questionName: '矩阵多选',
  component: 'MatrixMultipleChoice'
}, {
  questionType: 'SHORT',
  questionName: '简答题',
  component: 'Short'
}, {
  questionType: 'DATE',
  questionName: '日期题',
  component: 'Date'
}]

// 其他组件列表
export const othersList = [{
  otherType: 'PART',
  otherName: '标题',
  component: 'Part'
}, {
  otherType: 'PAGE',
  otherName: '分页',
  component: 'Page'
}]

// 题型组件对应统计分析组件
export const questionType2AnalysisComponent = {
  'SINGLE_CHOICE': 'CommonAnalysis', // 单选题
  'MULTIPLE_CHOICE': 'CommonAnalysis',  // 多选题
  'DROP_DOWN': 'CommonAnalysis',  //下拉题
  'SCALE': 'CommonAnalysis', // 量表
  'MATRIX_SCALE': 'MatrixScaleAnalysis', // 矩阵量表
  'MATRIX_SINGLE_CHOICE': 'MatrixAnalysis', // 矩阵单选
  'MATRIX_MULTIPLE_CHOICE': 'MatrixAnalysis', // 矩阵多选
  'SHORT': 'ShortOrDataAnalysis', // 简答题
  'DATE': 'ShortOrDataAnalysis',  //日期题
  'PAGE': 'Page', // 分页组件
  'PART': 'Part' // 章节组件
}

// 题型组件对应中文
export const questionType2Text = {
  'SINGLE_CHOICE': '单选题',
  'MULTIPLE_CHOICE': '多选题',
  'DROP_DOWN': '下拉题',
  'SCALE': '量表题',
  'MATRIX_SCALE': '矩阵量表题',
  'MATRIX_SINGLE_CHOICE': '矩阵单选题',
  'MATRIX_MULTIPLE_CHOICE': '矩阵多选题',
  'SHORT': '简答题',
  'DATE': '日期题',
}