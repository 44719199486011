/*
 * @Description: 数据构建
 * @version: 
 * @Author: PSG
 * @Date: 2021-04-14 18:09:48
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-26 18:29:55
 */
import createQuestionPrototypeEffect from '@/utils/question.js'
import { questionType2Component, questionType2AnalysisComponent } from '@/utils/enum.js'
import { groupBy } from '@/utils/arrayUtil'

const useCommonConstructEffect = () => {
  const createQuestionPrototype = createQuestionPrototypeEffect()
  // 根据题目id获取题目序号
  const getQuestionSortById = (questionId, questionnaire) => {
    const index = questionnaire.questions.findIndex(question => question.questionId === questionId)
    const targetQuestion = questionnaire.questions[index]
    const sort = targetQuestion ? Number(targetQuestion.sort) : null
    return sort
  }
  // 为题型间插入章节
  const insertPart = (questionnaire) => {
    if (!questionnaire) {
      return
    }
    let newQuestions = []
    const questions = questionnaire.questions || []
    const partMap = questionnaire.partMap || {}
    const partGroup = groupBy(questions, 'part')
    for (let partIndex in partMap) {   
      let pageIndex = partMap[partIndex].page
      let partPrototype = createQuestionPrototype('Part', pageIndex, Number(partIndex), '', partMap)
      if (!partGroup[partIndex]) {
        partGroup[partIndex] = [partPrototype]
      } else {
        partGroup[partIndex].unshift(partPrototype) 
      }
      newQuestions = newQuestions.concat(partGroup[partIndex])
    }
    if (partGroup[null]) {
      newQuestions = partGroup[null].concat(newQuestions)
    }
    questionnaire.questions = newQuestions
  }
  // 为题型间插入页码（页码在顶部）
  const insertPageInTop = (questionnaire) => {
    if (!questionnaire) {
      return
    }
    let newQuestions = []
    const questions = questionnaire.questions || []
    const pageMap = questionnaire.pageMap || {}
    const pageGroup = groupBy(questions, 'page')
    for (let pageIndex in pageMap) {
      let pagePrototype = createQuestionPrototype('Page', Number(pageIndex), null)
      if (!pageGroup[pageIndex]) {
        pageGroup[pageIndex] = [pagePrototype]
      } else {
        pageGroup[pageIndex].unshift(pagePrototype) 
      }
      newQuestions = newQuestions.concat(pageGroup[pageIndex])
    }
    questionnaire.questions = newQuestions
  }
  // 为题型间插入页码（页码在底部）
  const insertPageInBottom = (questionnaire) => {
    if (!questionnaire) {
      return
    }
    let newQuestions = []
    const questions = questionnaire.questions || []
    const pageMap = questionnaire.pageMap || {}
    const pageGroup = groupBy(questions, 'page')
    for (let pageIndex in pageMap) {
      let pagePrototype = createQuestionPrototype('Page', Number(pageIndex), null, '', pageMap)
      if (!pageGroup[pageIndex]) {
        pageGroup[pageIndex] = [pagePrototype]
      } else {
        pageGroup[pageIndex].push(pagePrototype)
      }
      newQuestions = newQuestions.concat(pageGroup[pageIndex])
    }
    questionnaire.questions = newQuestions
  }
  // 为每一道题加入sort排序字段
  const insertSort = (questionnaire) => {
    if (!questionnaire && !questionnaire.questions) return
    questionnaire.questions.filter(item => !/^(PAGE|PART)$/.test(item.questionType)).forEach((item, index) => item.sort = index) 
  }
  // 为设置为选项/行标题引用的题目过滤无用的无用的选项引用、行标题引用【只针对查看答卷功能】
  const filterUselessChoiceByAnswer = (question, questionnaire) => {
    const list = []
    const refQuestion = questionnaire.questions.find(item => item.questionId === question.ref)
    // 矩阵题
    if (/^MATRIX_/g.test(question.questionType)) {
      // refQuestion.
    // 单选、多选、下拉
    } else {

    }
    return list
  }
  // 为每一道题加入visibile字段
  const insertVisibile = (questionnaire) => {
    if (!questionnaire && !questionnaire.questions) return
    questionnaire.questions.forEach(question => {
      question.visible = question.showLogic ? false : true
      // 选项显示只针对单选题、多选题
      if (/^(SINGLE_CHOICE|MULTIPLE_CHOICE)$/.test(question.questionType)) {
        question.choices.forEach(choiceData => {
          choiceData.visible = choiceData.showLogic ? false : true
        })
      }
    })
  }
  // 根据answers将答案按quesitoinTd插入到对应题目中
  const insertAnswer = (questionnaire) => {
    if (questionnaire.answers) {
      questionnaire.answers.forEach(answer => {
        const targetQuestionId = answer.questionId
        const targetQuestion = questionnaire.questions.find(question => question.questionId === targetQuestionId)
        targetQuestion.answer = answer
      })
    }
  }
  // 设置跳转逻辑提示文字
  const setJumpLogicTips = (question, questionnaire) => {
    if (question.jumpLogic) {
      let jumpLogicMap = JSON.parse(question.jumpLogic)
      // 1.1、条件跳题
      if (Object.prototype.toString.call(jumpLogicMap) === '[object Object]') {
        question.isCondJump = true
        question.choices.forEach((choice, index) => {
          choice.jumpLogicTips = null // 先清空，再赋值
          if (jumpLogicMap.hasOwnProperty(choice.choiceId)) {
            const targetSort = getQuestionSortById(jumpLogicMap[choice.choiceId], questionnaire)
            choice.jumpLogicTips = (jumpLogicMap[choice.choiceId] === -2 ? '跳转到末尾结束作答' : `跳转到第${targetSort + 1}题`)
          }
        }) 
      // 1.2、无条件跳题
      } else {
        const targetSort = getQuestionSortById(jumpLogicMap, questionnaire)
        question.isNoCondJump = true
        question.jumpLogicTips = (jumpLogicMap === -2 
                                    ? '本题设置了跳题逻辑：无条件跳题，跳转到末尾结束作答' 
                                    : `本题设置了跳题逻辑：无条件跳题，跳转到第${targetSort + 1}题`)
      }
    } else {
      question.isCondJump = false
      question.isNoCondJump = false
      question.jumpLogicTips = null
      question.choices && question.choices.forEach(choice => choice.jumpLogicTips = null)
    }
  }
  // 设置显示逻辑提示文字
  const setShowLogicTips = (question, questionnaire) => {
    // 2.1、题目显示
    question.showLogicTips = question.showLogic 
      ? `${parseExpression2Tips(question.showLogic, questionnaire)}，显示当前题目`
      : null
    
    // 2.2、选项显示
    if (/^(SINGLE_CHOICE|MULTIPLE_CHOICE)$/.test(question.questionType)) {
      // 2.2.1、题目选项
      question.choices.forEach((choice) => {
        choice.showLogicTips = choice.showLogic 
          ? `${parseExpression2Tips(choice.showLogic, questionnaire)}，显示当前选项`
          : null
      })
      // 2.2.2、选项引用
      if (question.ref) {
        const refSort = getQuestionSortById(question.ref, questionnaire)
        question.refTips = `逻辑设置：本题设置了选项显示 - 选项引用 逻辑：选项取决于第${refSort + 1}题所选内容。`
      } else {
        question.refTips = null
      }
    }
    // 2.3、行标题引用
    if (/^(MATRIX_SINGLE_CHOICE|MATRIX_MULTIPLE_CHOICE|MATRIX_SCALE)$/.test(question.questionType)) {
      if (question.ref) {
        const refSort = getQuestionSortById(question.ref, questionnaire)
        question.refTips = `逻辑设置：本题设置了行标题引用逻辑：行标题取决于第${refSort + 1}题所选内容。`
      } else {
        question.refTips = null
      }
    }

    function parseExpression2Tips (expression, questionnaire) {
      let tips = ''
      let cond = expression.includes('|') ? '任一' : '全部'
      let sortList = []
      let targetQuestIds = []
      let targetQuestSorts = []
      expression.replace(/(\w+);/g, function () { 
        targetQuestIds.push(arguments[1]) 
      })
      targetQuestIds.forEach(targetQuestId => {
        const sort = getQuestionSortById(targetQuestId, questionnaire)
        targetQuestSorts.push(sort)
      })
      if (targetQuestSorts.length === 1) {
        tips = `符合第${targetQuestSorts[0] + 1}题条件`
      } else {
        tips = `符合${targetQuestSorts.map(sort => `第${sort + 1}题`).join('和')}的${cond}条件`
      }
      return tips
    }
  }
  // 设置引用逻辑提示文字
  const setRefLogicTips = (questionnaire) => {
    const refQuestions = questionnaire.questions.filter(quesiton => quesiton.ref)
    refQuestions.forEach(question => {
      // 1、选项引用提示设置
      if (/^(SINGLE_CHOICE|MULTIPLE_CHOICE)$/.test(question.questionType)) {
        if (question.ref) {
          question.refTips = `此题选项来源于前面题目的选中选项，请按顺序作答。`
        } else {
          question.refTips = null
        }
      }
      // 2、行标题引用提示设置
      if (/^(MATRIX_SINGLE_CHOICE|MATRIX_MULTIPLE_CHOICE|MATRIX_SCALE)$/.test(question.questionType)) {
        if (question.ref) {
          const refSort = getQuestionSortById(question.ref, questionnaire)
          question.refTips = `此题行标题来源于前面题目的选中选项，请按顺序作答`
        } else {
          question.refTips = null
        }
      }
    })
  }
  // 为每一道题加入逻辑设置的提示字段
  const insertLogicTips = (questionnaire) => {
    if (!questionnaire && !questionnaire.questions) return
    questionnaire.questions
      .filter(question => 
        !/^(PART|PAGE)$/g.test(question.questionType)
      )
      .forEach(question => {
        setJumpLogicTips(question, questionnaire)
        setShowLogicTips(question, questionnaire)
      })
  }
  // 为每一道题添加componentName字段（对应问卷题型组件）
  const handleQuestionType2Component = (questionnaire) => {
    questionnaire.questions && questionnaire.questions.map( (item) => {
      item.componentName = questionType2Component[item.questionType]
      return item
    })
  }
  // 为每一道题添加componentName字段（对于统计分析组件）
  const handleQuestionType2AnalysisComponent = (questionnaire) => {
    questionnaire.questions && questionnaire.questions.map( (item) => {
      item.componentName = questionType2AnalysisComponent[item.questionType]
      return item
    })
  }
  // 将答案放至每一道题的answer字段中
  const setAnswerInQuestion = (questionnaire) => {
    const answers = questionnaire.answers
    const questions = questionnaire.questions
    answers.forEach(answer => {
      questions.forEach(question => {
        if (answer.questionId === question.questionId) {
          question.answer = answer
        }
      })
    })
  }
  // 将统计信息放至每一道题的analysis字段中
  const setAnalysisInQuestion = (questionnaire) => {
    const analytics = questionnaire.analytics
    const questions = questionnaire.questions
    analytics.forEach(analysis => {
      questions.forEach(question => {
        if (analysis.questionId === question.questionId) {
          question.analysis = analysis
        }
      })
    })
  }
  // 构建编辑问卷结构
  const constructQuestion = (questionnaire) => {
    insertPart(questionnaire)
    insertPageInTop(questionnaire)
    insertSort(questionnaire)
    insertLogicTips(questionnaire)
    handleQuestionType2Component(questionnaire)
    return questionnaire
  }

  // 构建预览问卷结构
  const constructPrewiewQuestion = (questionnaire) => {
    insertPart(questionnaire)
    insertPageInTop(questionnaire)
    insertSort(questionnaire)
    insertLogicTips(questionnaire)
    handleQuestionType2Component(questionnaire)
    return questionnaire
  }
  // 构建做题问卷结构
  const constructExerciseQuestion = (questionnaire) => {
    insertPart(questionnaire)
    insertSort(questionnaire)
    insertVisibile(questionnaire)
    setRefLogicTips(questionnaire)
    handleQuestionType2Component(questionnaire)
    return questionnaire
  }
  // 构建带答案的问卷结构（针对断点续答）
  const constructExerciseQuestionWithAnswer = (questionnaire) => {
    insertAnswer(questionnaire)
    return questionnaire
  }
  // 构建答卷结构
  const constructQuestWithAnswer = (questionnaire) => {
    insertPart(questionnaire)
    insertPageInTop(questionnaire)
    insertSort(questionnaire)
    handleQuestionType2Component(questionnaire)
    setAnswerInQuestion(questionnaire)
    return questionnaire
  }
  // 构建答卷统计结构
  const constructQuestWithAnalysis = (questionnaire) => {
    insertPart(questionnaire)
    insertPageInTop(questionnaire)
    insertSort(questionnaire)
    setAnalysisInQuestion(questionnaire)
    handleQuestionType2AnalysisComponent(questionnaire)
    return questionnaire
  }
  return {
    insertLogicTips,
    
    constructQuestion,
    constructPrewiewQuestion,
    constructExerciseQuestion,
    constructExerciseQuestionWithAnswer,
    constructQuestWithAnswer,
    constructQuestWithAnalysis,
  }
}
export default useCommonConstructEffect