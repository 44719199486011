/*
 * @Descripttion: 深拷贝
 * @version: 
 * @Author: LCQ
 * @Date: 2020-08-24 11:34:09
 * @LastEditors: LCQ
 * @LastEditTime: 2020-08-24 11:42:13
 */

export function deepCopy(obj) {
  let result = Array.isArray(obj) ? [] : {}
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        result[key] = deepCopy(obj[key])
      } else {
        result[key] = obj[key]
      }
    }
  }
  return result
}
