/*
 * @Description: 节流函数
 * @version: 
 * @Author: HWL
 * @Date: 2021-07-08 14:22:19
 * @LastEditors: HWL
 * @LastEditTime: 2021-07-08 14:26:56
 */
function debounce(fn, key, wait) {
  if (!window.timer) {
    window.timer = {}
  }
  return function() {
    if (window.timer[key] !== null) {
      clearTimeout(window.timer[key])
    }
    window.timer[key] = setTimeout(fn, wait)
  }
}

export default debounce
