/*
 * @Description: 操作数组相关丰富
 * @version: 
 * @Author: PSG
 * @Date: 2021-05-07 17:41:33
 * @LastEditors: PSG
 * @LastEditTime: 2021-05-28 19:28:24
 */
import { deepCopy } from './deepCopy'
/**
 * 对象数组分组
 * @param arr 操作的数组对象
 * @param fn 可以是函数，可以是对象数组里面的某个属性（以该属性分组）
 * @returns 分组后的map
 */
export function groupBy (arr, fn) {
  return arr.map(typeof fn === 'function' ? fn : val => val[fn]).reduce((acc, val, i) => {
    acc[val] = (acc[val] || []).concat(arr[i])
    return acc
  }, {})
}

/**
 * 对象数组乱序(经典洗牌算法)
 * @param {Array} array 
 * @returns 乱序后的 randomArray
 */
export function shuffle(array) {
  let arrayCopy = deepCopy(array), // 副本
      arrayLength = arrayCopy.length,
      randomArray = [], // 乱序数组
      randomIndex, //随机数   
      tempItem //临时存储元素
  for (let i = arrayLength - 1; i >= 0; i--) {    
      randomIndex = Math.floor(Math.random() * (i + 1))
      tempItem = arrayCopy[randomIndex]
      arrayCopy[randomIndex] = arrayCopy[i]
      arrayCopy[i] = tempItem
  }
  arrayCopy.forEach(itemCopy => {
    randomArray.push(array.find(item => item.choiceId === itemCopy.choiceId))
  })
  return randomArray
}