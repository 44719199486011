/*
 * @Description: 新增题型，提供原始数据
 * @version: 
 * @Author: PSG
 * @Date: 2021-03-27 14:16:45
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-20 16:30:22
 */
import { createUniqueId } from './random'

/**
 * 新增题型，提供原始数据
 * @param {*} type 题目类型
 * @param {*} page 题目所处页码
 * @param {*} part 题目所处章节
 * @param {*} sort 题目所处序号
 * @returns 
 */
const createQuestionPrototypeEffect = () => {
  const createQuestionPrototype = (type, page, part, sort, partOrPageMap) => {
    let questionPrototype = {}
    switch (type) {
      // 单选题
      case 'SingleChoice':
        questionPrototype = {
          "sort": sort,
          "titleCSS" : "",
          "ifRequired" : true,
          "columnSplit" : 1,
          "title" : "",
          "ifMessUp" : false,
          "jumpLogic": null,
          "showLogic": null,
          "ref": null,
          "choices" : [
            {
              "ifAllowFillInBlanks" : false,
              "pic" : "",
              "ifRequired" : false,
              "choiceId" : createUniqueId(8),
              "description" : "",
              "text" : "",
            },
            {
              "ifAllowFillInBlanks" : false,
              "pic" : "ddd",
              "ifRequired" : false,
              "choiceId" : createUniqueId(8),
              "description" : "",
              "text" : "",
            }
          ],
          "questionId" : createUniqueId(10),
          "questionType" : "SINGLE_CHOICE",
          "componentName": type,
          "part" : part,
          "page" : page,
          "description" : ""
        }
        break
      // 多选题
      case "MultipleChoice":
        questionPrototype = {
          "sort": sort,
          "titleCSS" : "string",
          "ifRequired" : true,
          "columnSplit" : 1,
          "title" : "",
          "ifMessUp" : false,
          "jumpLogic": null,
          "showLogic": null,
          "ref": null,
          "choices" : [
            {
              "ifAllowFillInBlanks" : false,
              "pic" : "",
              "ifRequired" : false,
              "choiceId" : createUniqueId(8),
              "description" : "",
              "ifRepel": false,
              "text" : "",
            },
            {
              "ifAllowFillInBlanks" : false,
              "pic" : "",
              "ifRequired" : false,
              "choiceId" : createUniqueId(8),
              "description" : "",
              "ifRepel": true,
              "text" : "",
            }
          ],
          "questionId" : createUniqueId(10),
          "questionType" : "MULTIPLE_CHOICE",
          "componentName": type,
          "part" : part,
          "page" : page,
          "description" : ""
        }
        break
      // 下拉题
      case "DropDown":
        questionPrototype = {
          "sort": sort,
          "titleCSS" : "string",
          "ifRequired" : true,
          "title" : "",
          "ifMessUp" : false,
          "jumpLogic": null,
          "showLogic": null,
          "choices" : [
            {
              "choiceId" : createUniqueId(8),
              "text" : "",
            },
            {
              "choiceId" : createUniqueId(8),
              "text" : "",
            }
          ],
          "questionId" : createUniqueId(10),
          "questionType" : "DROP_DOWN",
          "componentName": type,
          "part" : part,
          "page" : page,
          "description" : ""
        }
        break
      // 量表题
      case "Scale":
        questionPrototype = {
          "sort": sort,
          "titleCSS" : "",
          "ifRequired" : true,
          "title" : "",
          "jumpLogic": null,
          "showLogic": null,
          "choices" : [
            {
              "value" : 1,
              "choiceId" : createUniqueId(8),
              "ifIgnore" : false,
              "text" : "很不满意"
            },{
              "value" : 2,
              "choiceId" : createUniqueId(8),
              "ifIgnore" : false,
              "text" : "不满意"
            },{
              "value" : 3,
              "choiceId" : createUniqueId(8),
              "ifIgnore" : false,
              "text" : "一般"
            },{
              "value" : 4,
              "choiceId" : createUniqueId(8),
              "ifIgnore" : false,
              "text" : "满意"
            },{
              "value" : 5,
              "choiceId" : createUniqueId(8),
              "ifIgnore" : false,
              "text" : "很满意"
            },
          ],
          "questionId" : createUniqueId(10),
          "questionType" : "SCALE",
          "componentName": type,
          "part" : part,
          "page" : page,        
          "description" : ""
        }
        break
      // 矩阵量表题
      case "MatrixScale":
        questionPrototype = {
          "sort": sort,
          "titleCSS" : "",
          "ifRequired" : true,
          "title" : "",
          "jumpLogic": null,
          "showLogic": null,
          "ref": null,
          "columnItems": [ 
            {
              "text": "很不熟悉",
              "value": 1,
              "ifIgnore": false
            },
            {
              "text": "不熟悉",
              "value": 2,
              "ifIgnore": false
            },
            {
              "text": "一般",
              "value": 3,
              "ifIgnore": false
            },
            {
              "text": "熟悉",
              "value": 4,
              "ifIgnore": false
            },
            {
              "text": "非常熟悉",
              "value": 5,
              "ifIgnore": false
            } 
          ],
          "rowItems": [
            {
              "text": "腾讯",
              "description": ""
            },
            {
              "text": "阿里巴巴",
              "description": ""
            },
            {
              "text": "百度",
              "description": ""
            }
          ],
          "questionId" : createUniqueId(10),
          "questionType" : "MATRIX_SCALE",
          "componentName": type,
          "part" : part,
          "page" : page,
          "description" : ""
        }
        break
      // 矩阵单选题
      case "MatrixSingleChoice":
        questionPrototype = {
          "sort": sort,
          "titleCSS" : "",
          "ifRequired" : true,
          "title" : "",
          "jumpLogic": null,
          "showLogic": null,
          "ref": null,
          "columnItems": [ 
            {
              "text": "很不熟悉", //列内容
            },
            {
              "text": "不熟悉",
            },
            {
              "text": "一般",
            },
            {
              "text": "熟悉",
            },
            {
              "text": "非常熟悉",
            },
          ],
          "rowItems": [
            {
              "text": "腾讯",
              "description": ""
            },
            {
              "text": "阿里巴巴",
              "description": ""
            },
            {
              "text": "百度",
              "description": ""
            }
          ],
          "questionId" : createUniqueId(10),
          "questionType" : "MATRIX_SINGLE_CHOICE",
          "componentName": type,
          "part" : part,
          "page" : page,
          "description" : ""
        }
        break
      // 矩阵多选题
      case "MatrixMultipleChoice":
        questionPrototype = {
          "sort": sort,
          "titleCSS" : "",
          "ifRequired" : true,
          "title" : "",
          "jumpLogic": null,
          "showLogic": null,
          "ref": null,
          "columnItems": [ 
            {
              "text": "剧集资源丰富"
            },
            {
              "text": "综艺资源丰富"
            },
            {
              "text": "增值服务性价比高"
            },
            {
              "text": "弹幕质量高"
            },
            {
              "text": "广告时间少"
            },
          ],
          "rowItems": [
            {
              "text": "芒果TV",
              "description": ""
            },
            {
              "text": "腾讯视频",
              "description": ""
            },
            {
              "text": "爱奇艺",
              "description": ""
            }
          ],
          "questionId" : createUniqueId(10),
          "questionType" : "MATRIX_MULTIPLE_CHOICE",
          "componentName": type,
          "part" : part,
          "page" : page,
          "description" : ""
        }
        break
      // 简单题
      case "Short":
        questionPrototype = {
          "sort": sort,
          "titleCSS" : "",
          "ifRequired" : true,
          "placeHolder" : "占位内容",
          "title" : "",
          "questionId" : createUniqueId(10),
          "questionType" : "SHORT",
          "componentName": type,
          "part" : part,
          "page" : page,
          "description" : "",
          "jumpLogic": null,
          "showLogic": null,
        }
        break
      // 日期题
      case "Date": 
        questionPrototype = {
          "sort": sort,
          "format" : "yyyy-MM",
          "titleCSS" : "",
          "ifRequired" : true,
          "title" : "",
          "questionId" : createUniqueId(10),
          "questionType" : "DATE",
          "componentName": type,
          "part" : part,
          "page" : page,
          "description" : "",
          "jumpLogic": null,
          "showLogic": null,
        }
        break
      // 页码
      case "Page": 
        questionPrototype = {
          "questionType" : "PAGE",
          "componentName": type,
          "questionId": createUniqueId(8),
          "part" : part,
          "page" : page,
          "maxPage": partOrPageMap? Math.max(...Object.keys(partOrPageMap)) : null
        }
        break
      // 章节
      case "Part": 
        questionPrototype = {
          "questionType" : "PART",
          "componentName": type,
          "questionId": createUniqueId(8),
          "part" : part,
          "page" : page,
          "title": partOrPageMap[part].title,
          "description": partOrPageMap[part].description
        }
        break
    }
    return questionPrototype
  }
  return createQuestionPrototype
}

export default  createQuestionPrototypeEffect